import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { bidForPlayer, loanBidForPlayer } from "../actions"
import marketValue from "../functions/marketValue"
import workerSend from "../appWorker"
import Button from "./Button";

function BidForPlayer(props) {
  const dispatch = useDispatch()
  const player = props.player
  const setPlayer = props.setPlayer
  const seasons = useSelector(state => state.seasons)
  const teams = useSelector(state => state.teams)
  const user = useSelector(state => state.user)
  const market = useSelector(state => state.market)
  const team = teams[user.teamId]
  const playerMarketValue = marketValue(player, seasons.length)
  let [fee, setFee] = useState(playerMarketValue)

  const makeBid = () => {
    let bid = {
      playerId: player.id,
      buyingTeamId: user.teamId,
      sellingTeamId: player.teamId,
      fee: fee,
      dateTime: user.currentTime,
      type: "transfer",
      status: ""
    }
    if (user.isLocalGame) {
      dispatch(bidForPlayer(bid))
    } else {
      workerSend("makeBid", { bid })
    }
  }

  const makeLoanBid = () => {
    if (user.isLocalGame) {
      dispatch(loanBidForPlayer(player.id, user.teamId, player.teamId, user.currentTime))
    } else {
      workerSend("makeLoanBid", { playerId: player.id, buyingTeamId: user.teamId, sellingTeamId: player.teamId })
    }
  }

  return (market.isOpen && user.teamId !== player.teamId && teams[user.teamId].bankBalance > 0) && (
    <section className="flex flex-col gap-4">
      <span>Bank Balance: £{team.bankBalance.toLocaleString()}</span>
      <span>Market Value: £{playerMarketValue}</span>
      <div className="flex gap-4 items-center">
        <label htmlFor="bid-amount">Bid amount</label>
        <input
          className="rounded-sm px-2 py-2 transition-all text-xs border border-slate-800"
          type="number"
          id="bid-amount"
          min="0"
          max={teams[user.teamId].bankBalance}
          placeholder={playerMarketValue}
          onChange={price => setFee(parseInt(price.target.value))} />
        <Button
          className="cta--accept"
          onClickFunction={() => { makeBid(); setPlayer(false) }}
          label={`Bid £${fee.toLocaleString()}`}
        />
      </div>
      {player.borrowingTeamId !== null &&
        <div className="flex gap-4 items-center">
          <button
            className="cta--accept"
            onClick={() => { makeLoanBid(); setPlayer(false) }}
          >Make loan bid</button>
        </div>
      }
    </section>
  )
}

export default BidForPlayer
